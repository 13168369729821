import React from 'react';
import {Routes,Route} from 'react-router-dom';
import Home from './components/Home';
import Layout from './components/Layout';
import Error from './components/Error';
import Jp50 from './components/Jp50';
//import Contect from './components/Contect';

function App() {
  return (
    <Layout>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/JP50' element={<Jp50 />} />
        <Route path='*' element={<Error />} />
      </Routes>
    </Layout>
  );
}




export default App;
