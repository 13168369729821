import React from "react";
import { Container,Row,Col,Button } from "reactstrap";

function Jp50(){
  /*
  const jp =  ['あ','い','う','え','お', 'か','き','く','け','こ','さ',  'し'  ,'す' ,'せ' ,'そ','た',  'ち'  ,  'つ'  , 'て','と' ,'な','に','ぬ','ね','の','は','ひ',  'ふ' ,'へ', 'ほ','ま','み','む','め','も', 'や','ゆ','よ','ら','り','る','れ','ろ','わ','ん','を'];
  //const jpp = ['ア','イ','ウ','エ','オ', 'カ','キ','ク','ケ','コ','サ',  'シ'  ,'ス' ,'セ' ,'ソ','タ',  'チ'  ,  'ツ'  , 'テ','ト' ,'ナ','ニ','ヌ','ネ','ノ','ハ','ヒ',  'フ' ,'ヘ', 'ホ','マ','ミ','ム','メ','モ', 'ヤ','ユ','ヨ','ラ','リ','ル','レ','ロ','ワ','ン','ヲ'];
  //const jpe = ['a' ,'i' ,'u' ,'e' ,'o' ,'ka','ki','ku','ke','ko','sa','si/shi','su','se' ,'so','ta','ti/chi','tu/tsu','te' ,'to','na','ni','nu','ne','no','ha','hi','hu/fu','he','ho','ma','mi','mu','me','mo','ya','yu','yo','ra','ri','ru','re','ro','wa','n','o' ];
  let tmp = [];
  let tts = jp;
  let c= 0;
  for (var i=0;i<10;i++){
    if (i===7){
      tmp[i] = [c+0,'',c+1,'',c+2];
      c=c+3;
     }else if (i===9){
      tmp[i] = [c+0,'',c+1,'',c+2];
      c=c+3;
    }else{
      tmp[i] = [c+0,c+1,c+2,c+3,c+4];
      c=c+5;
    }
  }  
  */
 let jp = new jpClass();
 jp.setJp = 0;
 let tmp = jp.getJp();
  
  return (
    <Container fluid className="Jp50">
      <Row>
        <Col className="text-center"><h3>五十音</h3></Col>
      </Row>
      <Row>
        <Col>
          <table>
            <tbdoy>
              {tmp.map( (item,i) => {
                return(
                  <tr key={i}>
                    <td><Button className="btnJp" block>{item[0]}</Button></td>
                    <td><Button className="btnJp" block>{item[1]}</Button></td>
                    <td><Button className="btnJp" block>{item[2]}</Button></td>
                    <td><Button className="btnJp" block>{item[3]}</Button></td>
                    <td><Button className="btnJp" block>{item[4]}</Button></td>
                  </tr>
                );
              })}
            </tbdoy>
          </table>
        </Col>
      </Row>
    </Container>    
  );
};

let jpClass = class jpClass{
  constructor(){
    this.tag = 1;
    this.jpn =  ['あ','い','う','え','お', 'か','き','く','け','こ','さ',  'し'  ,'す' ,'せ' ,'そ','た',  'ち'  ,  'つ'  , 'て','と' ,'な','に','ぬ','ね','の','は','ひ',  'ふ' ,'へ', 'ほ','ま','み','む','め','も', 'や','ゆ','よ','ら','り','る','れ','ろ','わ','ん','を'];
    this.jpp = ['ア','イ','ウ','エ','オ', 'カ','キ','ク','ケ','コ','サ',  'シ'  ,'ス' ,'セ' ,'ソ','タ',  'チ'  ,  'ツ'  , 'テ','ト' ,'ナ','ニ','ヌ','ネ','ノ','ハ','ヒ',  'フ' ,'ヘ', 'ホ','マ','ミ','ム','メ','モ', 'ヤ','ユ','ヨ','ラ','リ','ル','レ','ロ','ワ','ン','ヲ'];
    this.jpe = ['a' ,'i' ,'u' ,'e' ,'o' ,'ka','ki','ku','ke','ko','sa','si/shi','su','se' ,'so','ta','ti/chi','tu/tsu','te' ,'to','na','ni','nu','ne','no','ha','hi','hu/fu','he','ho','ma','mi','mu','me','mo','ya','yu','yo','ra','ri','ru','re','ro','wa','n','o' ];
    this.jp = [];
  }

  setJp(tag){
    this.tag = tag;
  }

  getJp(){
    let tmp = [];
    let c=0;
    if (this.tag === 1){
      tmp = this.jpn;
    }else if (this.tag === 2){
      tmp = this.jpp;
    }else if (this.tag === 3){
      tmp = this.jpe;
    }

    for (var i=0;i<10;i++){
      if (i===7){
        tmp[i] = [c+0,'',c+1,'',c+2];
        c=c+3;
       }else if (i===9){
        tmp[i] = [c+0,'',c+1,'',c+2];
        c=c+3;
      }else{
        tmp[i] = [c+0,c+1,c+2,c+3,c+4];
        c=c+5;
      }
    } 

    return tmp;    
  }

};


export default Jp50;
