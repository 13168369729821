import React from 'react';
import NavMenu from './NavMenu';

export default function Layout(props){
  return (
      <div className='app-wrapper'>
          <header className='p-0'><NavMenu /></header>
          <div className='container-fluid app-contents'>{props.children}</div>
      </div>
  );
}