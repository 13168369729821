import React from "react";
//import {Container} from 'reactstrap';


export default function Error(){
  return (
    <div className="container">
      <h3>Page Not Found.</h3>
    </div>
  );
}