import React from "react";
//import { Container,Row,Col } from "reactstrap";

export default function Home(){
  const style1 = {marginTop:'30px'};
  
    return (
    <div className="bgcolor-main" style={style1}>
      <div className="row">
        <div className="col col-md-6">A
        </div>
        <div className="col col-md-6">B
        </div>
      </div>
    </div>);
};