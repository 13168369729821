import React from 'react';
//import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
//, { useState }

export default function NavMenu(props){
/*
    const [isOpen,setOpen] = useState(false);

    const toggle = () =>{
        setOpen(!isOpen);
      }
*/    
    return (
        <div>
<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <a className="navbar-brand" href="/#">EZIS</a>
  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
    <span className="navbar-toggler-icon"></span>
  </button>
  <div className="collapse navbar-collapse" id="navbarScroll">
    <ul className="navbar-nav mr-auto my-2 my-lg-0 navbar-nav-scroll">
      <li className="nav-item active">
        <a className="nav-link" href="/#">Home </a>
      </li>
      <li className="nav-item">
        <a className="nav-link" href="/#">Link</a>
      </li>
    </ul>
  </div>
</nav>
</div>
    );
}

/*
<Navbar light expand="md" id="navbar">
            <NavbarBrand href="/">EZIS</NavbarBrand>
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                    <NavItem>
                        <NavLink href="/">首頁</NavLink>
                    </NavItem>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>展示</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem href="/JP50">五十音測驗</DropdownItem>
                            <DropdownItem>Option 2</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <NavItem>
                        <NavLink href="/Contect">連絡我</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </Navbar>
*/